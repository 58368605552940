<template>
  <div class="main_container">
    <div class="task_detail_container" v-loading="loading">

      <!-- 详情页头部 -->
      <detail-title
        :descriptionList="descriptionList"
        :backToPath="'/admin/manager'">
      </detail-title>

      <!-- 详情信息列表 -->
      <task-description
        :isMaterials="true"
        :descriptionList="descriptionList">
      </task-description>

      <!-- 关闭任务按钮 -->
      <div class="task_submit">
        <el-button
          size="small"
          type="danger"
          @click="closeTask"
          plain>
          关闭任务
        </el-button>
      </div>

    </div>

    <!-- 任务相关信息 -->
    <task-related-info></task-related-info>

  </div>
</template>

<script>
import api from '@/api/index'
import TaskRelatedInfo from '@/components/task-detail/related-info/index.vue'
import DetailTitle from '@/components/task-detail/title/index.vue'
import TaskDescription from '@/components/task-detail/description/index.vue'

export default {
  components: {
    TaskRelatedInfo,
    DetailTitle,
    TaskDescription
  },
  data () {
    return {
      descriptionList: {},
      loading: false
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    }
  },
  created () {
    this.getTaskDetail()
  },
  methods: {
    // 关闭任务
    closeTask () {
      this.$confirm('是否关闭该任务', '关闭任务', {
        confirmButtonText: '确认关闭'
      }).then(() => {
        api.adminCloseTask(this.taskId).then(res => {
          if (res.data.code === 0) {
            this.$message.success('操作成功')
            this.$router.push('/admin/manager')
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 获取任务详情
    getTaskDetail () {
      this.loading = true
      api.taskDetail(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.descriptionList = res.data.data
        } else {
          this.$message.error('获取信息失败')
        }
      }).catch(() => {
        this.$message.error('请求异常')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main_container {
  width: 100%;
  height: 100%;
  display: flex;
}
.task_detail_container {
  min-width: 1200px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  .task_submit {
    height: 80px;
    padding: 0 30px;
    border-top: 1px solid #E4E7ED;
    display: flex;
    align-items: center;
  }
}
</style>
